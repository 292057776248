import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      regex: "",
      minChars: 2,
      correct: false,
      error: "La réponse doit être de 3 caractères minimum"
    };
  }

  next() {
    if (this.state.correct) {
      this.props.nextStep();
    }
  }
  componentDidMount() {
    fetch("https://bit.ly/Formstep01", { mode: "no-cors" });
    if (
      this.props.parentState.name.length > this.state.minChars &&
      this.props.parentState.name.trim().match(/^[a-zA-Z_ ]+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "La réponse doit être de 3 caractères minimum",
        correct: false
      });
    }
  }
  handleChange = e => {
    if (
      e.target.value.length > this.state.minChars &&
      e.target.value.trim().match(/^[a-zA-Z_ ]+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      this.setState({
        error: "La réponse doit être de 3 caractères minimum",
        correct: false
      });
    }
    this.props.setParentState({ name: e.target.value });
  };
  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>

          <BrowserView>
            <Typography
              style={{
                width: "90%",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: "10px",
                textAlign: "left"
              }}
              gutterBottom
            >
              Avant de démarrer la recherche, faisons un peu connaissance!
              <b> Comment dois-je vous appeler</b> ? 😉
              <Typography
                variant="caption"
                display="block"
                style={{ color: "grey" }}
                gutterBottom
              >
                (Nom et prénom)
              </Typography>
            </Typography>
          </BrowserView>
          <MobileView>
            <Typography
              style={{
                width: "90%",
                marginLeft: "5%",
                marginRight: "5%",
                marginBottom: "10px",
                textAlign: "left"
              }}
              gutterBottom
            >
              Pour vous enregistrer et démarrer votre recherche, entrez votre
              nom et prénom.
              <Typography
                variant="caption"
                display="block"
                style={{ color: "grey" }}
                gutterBottom
              >
                (Nom et prénom)
              </Typography>
            </Typography>
          </MobileView>

          <TextField
            id="standard-basic"
            value={this.props.parentState.name}
            placeholder="Répondez ici..."
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "60px"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Ok
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "red" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          {this.state.correct ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
