import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

import { withRouter } from "react-router";
class Step extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      token: props.match.params.p,
      password: "",
      regex: "",
      minChars: 8,
      correct: false,
      correctPassword: false,
      error: ""
    };
  }

  next = async () => {
    if (this.state.correct && this.state.correctPassword && this.state.token) {
      this.setState({ correct: false });
      try {
        let response = await fetch(
          "https://api.dabadar.com/api/user/resetPassword",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              token: this.state.token,
              email: this.props.parentState.email.toLowerCase().trim(),
              password: this.props.parentState.password
            })
          }
        );

        let responseJson = await response.json();
        if (response.status == 400) {
          this.setState({
            error: "Une erreur s'est produite",
            correct: false
          });
        } else {
          this.setState({ correct: true, created: true });
          this.props.nextStep();
        }
      } catch (error) {
        this.setState({ error: "Une erreur s'est produite", created: false });
      }
    }
  };

  componentDidMount() {
    if (
      this.props.parentState.email
        .trim()
        .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "Format e-mail invalide",
        correct: false
      });
    }

    if (
      this.props.parentState.password.length > this.state.minChars &&
      this.props.parentState.password.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?!.*[^a-zA-Z0-9*@#$^+=])(.{8,})$/
      )
    ) {
      this.setState({
        error: "",
        correctPassword: true
      });
    } else {
      this.setState({
        error:
          "Le mot de passe doit être de 8 caractères minimum et doit contenir au minimum une majuscule,une minuscule et un nombre",
        correctPassword: false
      });
    }
  }

  handleChange = e => {
    if (
      e.target.value
        .trim()
        .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "Email invalide",
        correct: false
      });
    }
    this.props.setParentState({ email: e.target.value.trim() });
  };
  handleChangePassword = e => {
    if (
      e.target.value.length > this.state.minChars &&
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?!.*[^a-zA-Z0-9*@#$^+=])(.{8,})$/
      )
    ) {
      this.setState({
        error: "",
        correctPassword: true
      });
    } else {
      this.setState({
        error:
          "Le mot de passe doit être de 8 caractères minimum et doit contenir au minimum une majuscule,une minuscule et un nombre",
        correctPassword: false
      });
    }
    this.props.setParentState({ password: e.target.value });
  };

  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          ></Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              textAlign: "center"
            }}
            gutterBottom
          >
            Veuillez renseigner votre email ainsi que votre nouveau password.
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              (Nécessaire pour réinitialiser votre mot de passe)
            </Typography>
          </Typography>
          <TextField
            id="standard-basic"
            value={this.props.parentState.email}
            placeholder="mail@example.com"
            style={{
              width: "90%",
              marginBottom: "30px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          <TextField
            id="pass-basic"
            type="password"
            value={this.props.parentState.password}
            placeholder="Nouveau mot de passe"
            style={{
              width: "90%",
              marginBottom: "30px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChangePassword(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct && this.state.correctPassword ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Envoyer
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
      </>
    );
  }
}

export default withRouter(Step);
