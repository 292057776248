import React from "react";
import Container from "@material-ui/core/Container";

import Typography from "@material-ui/core/Typography";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      created: false,
      error: ""
    };
  }

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              textAlign: "center"
            }}
            gutterBottom
          >
            Merci ! Votre mot de passe est initilaisé 🎯
          </Typography>
        </Container>
      </>
    );
  }
}

export default Step;
