import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import Filter1Icon from "@material-ui/icons/Filter1";
import Filter2Icon from "@material-ui/icons/Filter2";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: false,
      correct: false,
      error: ""
    };
  }
  componentDidMount() {
    fetch("https://bit.ly/Formstep02", { mode: "no-cors" });
  }
  next(obj) {
    this.props.setParentState({ is_New: obj });
    this.props.nextStep();
  }

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              textAlign: "left"
            }}
            gutterBottom
          >
            Enchanté {this.props.parentState.name} 😬 ! Par quel type de bien
            immobilier êtes-vous intéressé ?*
          </Typography>
          <div
            style={{
              display: "inline-block",
              minWidth: "200px",
              width: "90%",
              textAlign: "left",
              marginRight: "0 5% 0 5%"
            }}
          >
            <Button
              variant="contained"
              color="default"
              startIcon={<Filter1Icon />}
              onClick={() => this.next(true)}
              style={{
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "10px",
                marginRight: "10px"
              }}
            >
              Immobilier neuf
            </Button>{" "}
            <Button
              variant="contained"
              color="default"
              onClick={() => this.next(false)}
              style={{
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "10px",
                marginRight: "10px"
              }}
              startIcon={<Filter2Icon />}
            >
              Peu importe
            </Button>
          </div>
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          <IconButton
            onClick={this.props.previousStep}
            color="primary"
            style={{ backgroundColor: "#36d440", color: "#001A14" }}
            aria-label="add to shopping cart"
          >
            <ArrowLeftIcon />
          </IconButton>
        </div>
      </>
    );
  }
}

export default Step;
