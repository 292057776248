import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      regex: "",
      minChars: 7,
      correct: false,
      error: ""
    };
  }

  next() {
    this.props.nextStep();
  }
  componentDidMount() {
    if (
      this.props.parentState.name.length > this.state.minChars &&
      this.props.parentState.name.match(/^[a-zA-Z_ ]*$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "La réponse doit être de 3 caractères minimum",
        correct: false
      });
    }
  }
  handleChange = e => {
    if (
      e.target.value.length > this.state.minChars &&
      e.target.value.match(/^[a-zA-Z_ ]*$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "La réponse doit être de 3 caractères minimum",
        correct: false
      });
    }
    this.props.setParentState({ name: e.target.value });
  };
  handleKeyPress = event => {
    if (event.key === "Enter") {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <img style={{ maxWidth: "100%" }} src="../../dabadar.png"></img>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              marginTop: "50px",
              textAlign: "center",
              fontSize: "1.17em"
            }}
            gutterBottom
          >
            Dites-nous ce que vous cherchez en 1 minute chrono ⏱️ <br></br>
            DabaDar s’occupe du reste !
          </Typography>
          <Button
            variant="contained"
            onClick={() => this.next()}
            color="primary"
            style={{
              fontWeight: 600,
              backgroundColor: "#36d440",
              color: "#001A14"
            }}
            endIcon={<Icon>check</Icon>}
          >
            Démarrer
          </Button>
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          {this.state.correct ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
