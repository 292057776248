import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      correct: false,
      error: "Minimun 21 métres"
    };
  }

  componentDidMount() {
    fetch("https://bit.ly/Formstep04", { mode: "no-cors" });
    if (this.props.parentState.surface)
      if (
        !isNaN(this.props.parentState.surface) &&
        this.props.parentState.surface > 20
      ) {
        this.setState({
          error: "",
          correct: true
        });
      } else {
        this.setState({
          error: "Minimun 21 métres",
          correct: false
        });
      }
  }

  next() {
    if (this.state.correct) {
      this.props.nextStep();
    }
  }
  handleChange = e => {
    if (e.target.value)
      if (!isNaN(e.target.value) && e.target.value > 20) {
        this.setState({
          error: "",
          correct: true
        });
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth"
        });
      } else {
        this.setState({
          error: "Minimun 21 métres",
          correct: false
        });
      }
    this.props.setParentState({ surface: e.target.value });
  };
  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              textAlign: "left"
            }}
            gutterBottom
          >
            Et quelle <b>surface minimum</b> (en m²) vous conviendrait ?*
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              (*Merci d'indiquer uniquement un nombre.)
            </Typography>
          </Typography>
          <TextField
            id="standard-basic"
            type="number"
            value={this.props.parentState.surface}
            placeholder="Répondez ici..."
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "60px"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Ok
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "red" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          <IconButton
            onClick={this.props.previousStep}
            color="primary"
            style={{ backgroundColor: "#36d440", color: "#001A14" }}
            aria-label="add to shopping cart"
          >
            <ArrowLeftIcon />
          </IconButton>
          {this.state.correct ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
