import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      correct: false,
      error: "",
      created: false
    };
  }

  componentDidMount() {
    fetch("https://bit.ly/Formstep06", { mode: "no-cors" });
    if (this.props.parentState.phone.match(/^0\d{9}$/)) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "Le numéro de téléphone doit comporter au minimum 10 chiffres",
        correct: false
      });
    }
  }
  next = async () => {
    if (this.state.correct && this.state.created == false) {
      try {
        let response = await fetch(
          "https://api.dabadar.com/api/user/checkMail",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              email: this.props.parentState.email.toLowerCase(),
              phone: this.props.parentState.phone
            })
          }
        );

        let responseJson = await response.json();
        if (response.status == 400) {
          this.setState({
            error: "Le numéro de téléphone est déjà utilisé",
            created: false,
            correct: false
          });
        } else {
          this.setState({ correct: true, created: true });
          this.props.nextStep();
        }
      } catch (error) {
        this.setState({ error: "Une erreur s'est produite", created: false });
      }
    }
  };
  handleChange = e => {
    if (e.target.value.match(/^0\d{9}$/)) {
      this.setState({
        error: "",
        correct: true
      });
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      this.setState({
        error: "Le numéro de téléphone doit comporter au minimum 10 chiffres",
        correct: false
      });
    }
    this.props.setParentState({ phone: e.target.value });
  };
  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              textAlign: "left"
            }}
            gutterBottom
          >
            Et enfin, laissez-nous votre numéro de téléphone pour valider votre
            recherche ❗*
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              (*Nécessaire pour le traitement de votre demande)
            </Typography>
          </Typography>
          <TextField
            id="standard-basic"
            value={this.props.parentState.phone}
            type="number"
            placeholder="Votre Numéro de Téléphone"
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "60px"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Ok
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          {this.state.correct ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
