import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import StepWizard from "react-step-wizard";

import Step0 from "../src/steps/step0";
import Step1 from "../src/steps/step1";
import Step2 from "../src/steps/step2";
import Step3 from "../src/steps/step3";
import Step4 from "../src/steps/step4";
import Step5 from "../src/steps/step5";
import Step6 from "../src/steps/step6";
import Step7 from "../src/steps/step7";
import Recovery from "../src/steps/recovery";
import RecoveryDone from "../src/steps/recoveryDone";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      email: "",
      phone: "",
      password: "",
      surface: "",
      price: "",
      is_New: false
    };
  }
  render() {
    return (
      <div className="App">
        <Router>
          <Switch>
            <Route path="/recovery/:p">
              <StepWizard isLazyMount={true}>
                <Recovery
                  location={this.props.location}
                  parentState={this.state}
                  setParentState={obj => this.setState(obj)}
                ></Recovery>
                <RecoveryDone
                  location={this.props.location}
                  parentState={this.state}
                  setParentState={obj => this.setState(obj)}
                ></RecoveryDone>
              </StepWizard>
            </Route>
            <Route path="/">
              <BrowserView>
                <StepWizard isLazyMount={true}>
                  <Step0
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step0>
                  <Step1
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step1>
                  <Step3
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step3>
                  <Step4
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step4>
                  <Step5
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step5>
                  <Step6
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step6>
                  <Step7
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step7>
                </StepWizard>
              </BrowserView>
              <MobileView>
                <StepWizard isLazyMount={true}>
                  <Step1
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step1>
                  <Step3
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step3>
                  <Step4
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step4>
                  <Step5
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step5>
                  <Step6
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step6>
                  <Step7
                    parentState={this.state}
                    setParentState={obj => this.setState(obj)}
                  ></Step7>
                </StepWizard>
              </MobileView>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
}
export default App;
