import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      password: "",
      regex: "",
      minChars: 7,
      correct: false,
      correctPassword: false,
      error: ""
    };
  }

  next = async () => {
    if (this.state.correct && this.state.correctPassword) {
      this.setState({ correct: false });
      try {
        let response = await fetch(
          // "https://vodomss3uc.execute-api.eu-west-1.amazonaws.com/prod/api/user/checkMail",
          "https://api.dabadar.com/api/user/register",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              name: this.props.parentState.name,
              email: this.props.parentState.email.toLowerCase().trim(),
              password: this.props.parentState.password,
              surface: this.props.parentState.surface,
              price: this.props.parentState.price,
              is_New: this.props.parentState.is_New
            })
          }
        );

        let responseJson = await response.json();
        if (response.status == 400) {
          this.setState({
            error: "Email déja inscrit",
            correct: false
          });
        } else {
          this.setState({ correct: true, created: true });
          this.props.nextStep();
        }
      } catch (error) {
        this.setState({ error: "Une erreur s'est produite", created: false });
      }
    }
  };

  componentDidMount() {
    fetch("https://bit.ly/Formstep05", { mode: "no-cors" });
    if (
      this.props.parentState.email
        .trim()
        .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
    } else {
      this.setState({
        error: "Format e-mail invalide",
        correct: false
      });
    }

    if (
      this.props.parentState.password.length > this.state.minChars &&
      this.props.parentState.password.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?!.*[^a-zA-Z0-9*@#$^+=])(.{8,})$/
      )
    ) {
      this.setState({
        error: "",
        correctPassword: true
      });
    } else {
      this.setState({
        error:
          "Le mot de passe doit être de 8 caractères minimum et doit contenir au minimum une majuscule,une minuscule et un nombre",
        correctPassword: false
      });
    }
  }

  handleChange = e => {
    if (
      e.target.value
        .trim()
        .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      this.setState({
        error: "",
        correct: true
      });
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      this.setState({
        error: "Email invalide",
        correct: false
      });
    }
    this.props.setParentState({ email: e.target.value.trim() });
  };
  handleChangePassword = e => {
    if (
      e.target.value.length > this.state.minChars &&
      e.target.value.match(
        /^(?=.*[0-9])(?=.*[A-Z])(?!.*[^a-zA-Z0-9*@#$^+=])(.{8,})$/
      )
    ) {
      this.setState({
        error: "",
        correctPassword: true
      });
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    } else {
      this.setState({
        error:
          "Le mot de passe doit être de 8 caractères minimum et doit contenir au minimum une majuscule,une minuscule et un nombre",
        correctPassword: false
      });
    }
    this.props.setParentState({ password: e.target.value });
  };

  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              textAlign: "left"
            }}
            gutterBottom
          >
            Parfait 👌 ! Veuillez saisir une adresse <b>email</b> et un <b>mot de passe</b> afin de créer votre
            compte DabaDar.*
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              (Nécessaire pour le traitement de votre demande)
            </Typography>
          </Typography>
          <TextField
            id="standard-basic"
            value={this.props.parentState.email}
            placeholder="Votre Adresse Email"
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          <TextField
            id="pass-basic"
            type="password"
            value={this.props.parentState.password}
            placeholder="Votre Mot de Passe"
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            onChange={e => this.handleChangePassword(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct && this.state.correctPassword ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "60px"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Ok
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "red" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          <IconButton
            onClick={this.props.previousStep}
            color="primary"
            style={{ backgroundColor: "#36d440", color: "#001A14" }}
            aria-label="add to shopping cart"
          >
            <ArrowLeftIcon />
          </IconButton>
          {this.state.correct && this.state.correctPassword ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
