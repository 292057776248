import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      created: false,
      error: ""
    };
  }
  componentDidMount() {
    fetch("https://bit.ly/Formstep07", { mode: "no-cors" });
  }
  getErreur(response) {
    let msg = "Une erreur s'est produite";
    if (response.status.toLowerCase().includes("name"))
      msg = "Le nom doit un avoir 6 characteres minimum";
    if (response.status.toLowerCase().includes("email")) msg = "Email invalide";
    if (response.status.toLowerCase().includes("phone"))
      msg = "Telephone invalide";
    if (response.status.toLowerCase().includes("password"))
      msg = "Mot de passe trop court";
    return msg;
  }

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "30px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              marginBottom: "30px",
              textAlign: "center",
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            gutterBottom
          >
            Bien noté {this.props.parentState.name}! Votre compte a été créé
            avec succès!
          </Typography>
          <BrowserView>
            <h4>
              Il vous suffit maintenant de télécharger notre application mobile
              pour commencer à recevoir les annonces qui correspondent à vos
              critères.🎯 👇
            </h4>

            <a href="https://dabadar.com">
              <img
                style={{
                  display: "none",
                  width: "200px",
                  paddingBottom: "12px"
                }}
                src="../../apple-store-badge.png"
              ></img>
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.dabadar.dabadar&hl=en">
              <img
                style={{ width: "230px" }}
                src="../../google-play-badge.png"
              ></img>
            </a>
          </BrowserView>
          <MobileView>
            <h4>
              Il vous suffit maintenant de cliquer sur "Accueil" (en bas👇) pour
              vous authentifier et accéder à votre compte.
            </h4>
          </MobileView>
        </Container>
      </>
    );
  }
}

export default Step;
