import React from "react";
import Container from "@material-ui/core/Container";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Icon from "@material-ui/core/Icon";
import IconButton from "@material-ui/core/IconButton";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import CloseIcon from "@material-ui/icons/Close";

class Step extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      field: "",
      correct: false,
      error: "Minimum 100 000 DHS",
      minVal: 100000
    };
  }
  componentDidMount() {
    fetch("https://bit.ly/Formstep03", { mode: "no-cors" });
    if (this.props.parentState.price)
      if (
        !isNaN(this.props.parentState.price) &&
        this.props.parentState.price >= 100000
      ) {
        this.setState({
          error: "",
          correct: true
        });
      } else {
        this.setState({
          error: "Minimum 100 000 DHS",
          correct: false
        });
      }
  }
  next() {
    if (this.state.correct == true) {
      this.props.nextStep();
    }
  }
  handleChange = e => {
    if (e.target.value)
      if (!isNaN(e.target.value) && e.target.value >= 100000) {
        this.setState({
          error: "",
          correct: true
        });
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth"
        });
      } else {
        this.setState({
          error: "Minimum 100 000 DHS",
          correct: false
        });
      }
    this.props.setParentState({ price: e.target.value });
  };
  handleKeyPress = event => {
    if (event.key === "Enter" && this.state.correct == true) {
      this.next();
    }
  };

  render() {
    return (
      <>
        <Container
          style={{
            height: "100vh",
            width: "100vw",
            verticalAlign: "middle",
            display: "table-cell",
            minWidth: "100vw"
          }}
        >
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              color: "grey",
              textAlign: "center"
            }}
            gutterBottom
            variant="caption"
            display="block"
          >
            Etape {this.props.currentStep} sur {this.props.totalSteps}
          </Typography>
          <Typography
            style={{
              width: "90%",
              marginLeft: "5%",
              marginRight: "5%",
              marginBottom: "10px",
              textAlign: "left"
            }}
            gutterBottom
          >
            Top 👍! Quel est votre <b>budget (en Dirham)</b> ?*
            <Typography
              variant="caption"
              display="block"
              style={{ color: "grey" }}
              gutterBottom
            >
              (*Vous allez recevoir des résultats correspondant <b>à plus ou moins 20%</b> du budget indiqué.)
            </Typography>
          </Typography>
          <TextField
            id="standard-basic"
            type="number"
            value={this.props.parentState.price}
            placeholder="Répondez ici..."
            style={{
              width: "90%",
              marginBottom: "10px",
              marginLeft: "5%",
              marginRight: "5%"
            }}
            required
            onChange={e => this.handleChange(e)}
            onKeyPress={e => this.handleKeyPress(e)}
          />
          {this.state.correct ? (
            <Button
              variant="contained"
              onClick={() => this.next()}
              color="primary"
              style={{
                fontWeight: 600,
                backgroundColor: "#36d440",
                color: "#001A14",
                marginBottom: "60px"
              }}
              endIcon={<Icon>check</Icon>}
            >
              Ok
            </Button>
          ) : (
            <Typography
              variant="caption"
              display="block"
              style={{ color: "red" }}
              gutterBottom
            >
              {this.state.error}
            </Typography>
          )}
        </Container>
        <div style={{ position: "absolute", bottom: "5%", right: "2%" }}>
          <IconButton
            onClick={this.props.previousStep}
            color="primary"
            style={{ backgroundColor: "#36d440", color: "#001A14" }}
            aria-label="add to shopping cart"
          >
            <ArrowLeftIcon />
          </IconButton>
          {this.state.correct ? (
            <IconButton
              onClick={() => this.next()}
              color="primary"
              aria-label="add to shopping cart"
              style={{ backgroundColor: "#36d440", color: "#001A14" }}
            >
              <ArrowRightIcon />
            </IconButton>
          ) : null}
        </div>
      </>
    );
  }
}

export default Step;
